<template>
    <admin-dashboard-layout>
        <v-row class="items-center">
            <v-col cols="3">
                <text-field
                    v-model="filters.userName"
                    label="username"
                    @input="handleFilter"
                />
            </v-col>

            <v-col cols="2">
                <v-select
                    label="Status"
                    :items="statusOptions"
                    item-text="text"
                    item-value="value"
                    v-model="filters.status"
                />
            </v-col>

            <v-col v-if="selected.length > 0" cols="2">
                <v-btn
                    class="btn-accent mt-1"
                    block
                    text
                    @click="downloadPdfInBulk()"
                >
                    PDF ({{ selected.length }})
                </v-btn>
            </v-col>

            <v-col v-if="selected.length > 0" cols="2">
                <v-btn
                    class="btn-accent mt-1"
                    block
                    text
                    @click="downloadCsv()"
                >
                    CSV ({{ selected.length }})
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="filteredItems"
            :items-per-page="5"
            class="elevation-1"
            show-select
            v-model="selected"
            @click:row="handleClickRow"
        >
            <template v-slot:item.user="{ item }">
                {{ item.user.slug }}
            </template>
            <template v-slot:item.status="{ item }">
                {{ statuses[item.status] }}
            </template>
            <template v-slot:item.payment_system="{ item }">
                {{ paymentSystems[item.payment_system] }}
            </template>
            <template v-slot:item.download_pdf="{ item }">
                <button @click="downloadPdf(item)">Download PDF</button>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import moment from "moment";
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import { paymentSystems, statuses } from "@/views/admin/withdrawals/withdrawal";
import TextField from "@/components/app/form/TextField.vue";
import WithdrawalBitSafe from "@/models/admin/WithdrawalBitSafe";
import WithdrawalBankTransfer from "@/models/admin/WithdrawalBankTransfer";

export default {
    name: "withdrawals-index",
    components: { TextField, AdminDashboardLayout },
    data: function () {
        return {
            filters: {
                userName: "",
                status: null,
            },
            loading: false,
            items: [],
            itemsBitSafe: [],
            itemsBankTransfer: [],
            headers: [
                { text: "ID", value: "id" },
                { text: "User", value: "user" },
                { text: "Amount In", value: "amount_in" },
                { text: "Commission", value: "commission" },
                { text: "Amount out", value: "amount_out" },
                { text: "Status", value: "status" },
                { text: "Payment System", value: "payment_system" },
                { text: "Created time", value: "created_at" },
                { text: "", value: "download_pdf" },
            ],
            selected: [],
        };
    },
    computed: {
        statuses() {
            return statuses;
        },
        paymentSystems() {
            return paymentSystems;
        },
        statusOptions() {
            const items = [{ value: null, text: "All" }];
            for (const key in statuses) {
                items.push({ text: statuses[key], value: key });
            }
            return items;
        },
        filteredItems() {
            let filteredItems = this.items;

            if (this.filters.status) {
                filteredItems = filteredItems.filter(
                    (item) => item.status === Number(this.filters.status)
                );
            }

            return filteredItems;
        },
    },
    methods: {
        handleClickRow(items) {
            this.$router.push({
                name: `admin.withdrawals.${
                    items.payment_system === "bitsafe"
                        ? "bitsafe"
                        : "bank-transfer"
                }.show`,
                params: { id: items.id },
            });
        },
        handleFilter() {
            this.fetchWithdrawals();
        },
        async fetchBitSafeWithdrawals() {
            this.loading = true;
            let qb = WithdrawalBitSafe;
            if (this.filters.userName) {
                qb = qb.where("user-name", this.filters.userName);
            }
            qb = qb.whereIn("status", [2, 3]);
            this.itemsBitSafe = await qb.get();
            this.itemsBitSafe.map(
                (v) => (v.created_at = moment(v.created_at).format("lll"))
            );
            this.loading = false;
        },
        async fetchBankTransferWithdrawals() {
            this.loading = true;
            let qb = WithdrawalBankTransfer;
            if (this.filters.userName) {
                qb = qb.where("user-name", this.filters.userName);
            }
            qb = qb.whereIn("status", [2, 3]);
            this.itemsBankTransfer = await qb.get();
            this.itemsBankTransfer.map(
                (v) => (v.created_at = moment(v.created_at).format("lll"))
            );
            this.loading = false;
        },
        async fetchWithdrawals() {
            await this.fetchBitSafeWithdrawals();
            await this.fetchBankTransferWithdrawals();

            this.items = [...this.itemsBitSafe, ...this.itemsBankTransfer];
        },
        downloadPdf(invoice) {
            axios
                .post(
                    "/api/admin/invoice/download/pdf",
                    {
                        invoice_id: invoice.id,
                        user_name:
                            invoice.payment_data.full_name || invoice.user.name,
                        user_slug: invoice.user.slug,
                        payment_system: invoice.payment_system,
                        iban: invoice.payment_data.iban,
                        total: invoice.amount_in,
                        date: invoice.created_at,
                    },
                    { responseType: "blob" }
                )
                .then((response) => {
                    if (response.status === 200 && !response.data.why_code) {
                        const downloadUrl = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.setAttribute("download", "invoice.pdf");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        downloadPdfInBulk() {
            const invoices = this.selected.map((invoice) => {
                return {
                    invoice_id: invoice.id,
                    user_name:
                        invoice.payment_data.full_name || invoice.user.name,
                    user_slug: invoice.user.slug,
                    payment_system: invoice.payment_system,
                    iban: invoice.payment_data.iban,
                    total: invoice.amount_in,
                    date: invoice.created_at,
                };
            });

            axios
                .post(
                    "/api/admin/invoice/download/pdf/bulk",
                    {
                        invoices,
                    },
                    { responseType: "blob" }
                )
                .then((response) => {
                    if (response.status === 200 && !response.data.why_code) {
                        const downloadUrl = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.setAttribute("download", "invoices.zip");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /*
         * download csv
         */
        prepareInvoicesForDownload(invoices) {
            return invoices.map((invoice) => {
                return {
                    invoice_id: invoice.id,
                    user_name:
                        invoice.payment_data.full_name || invoice.user.name,
                    user_slug: invoice.user.slug,
                    payment_system: invoice.payment_system,
                    iban: invoice.payment_data.iban,
                    total: invoice.amount_in,
                    date: invoice.created_at,
                };
            });
        },
        downloadCsv() {
            const invoices = this.prepareInvoicesForDownload(this.selected);

            axios
                .post(
                    "/api/admin/invoice/download/csv",
                    {
                        invoices,
                    },
                    { responseType: "blob" }
                )
                .then((response) => {
                    if (response.status === 200 && !response.data.why_code) {
                        const downloadUrl = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.setAttribute("download", "invoices.csv");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    created() {
        this.fetchWithdrawals();
    },
};
</script>
